<template>
    <div class="import-payment">
        <p>Payment Processing</p>

    </div>
</template>

<script>
    export default {
        name: "Payment",
        data() {
            return {
                //
            }
        }
    }
</script>

<style scoped>

</style>