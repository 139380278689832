<template>
    <div class="nft-platform">
        <div class="row text-center small_space benefit_wrap">
            <div class="col-lg-4 col-md-4 col-6">
                <div class="benefit_box animation selected" data-animation="fadeInUp" data-animation-delay="0.1s"
                     v-on:click="selectPlatform(1)">
                    <img class="img-fluid" src="@/assets/nft_import/logo_opensea.png" alt="secure"/>
                    <h6>OpenSea</h6>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-6">
                <div class="benefit_box animate__animated animate__fadeInUp" data-animation="fadeInUp" data-animation-delay="0.2s"
                     v-on:click="selectPlatform(2)">
                    <img class="img-fluid" src="@/assets/nft_import/logo_rarible.png" alt="token"/>
                    <h6>Rarible</h6>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-6">
                <div class="benefit_box animation disabled" data-animation="fadeInUp" data-animation-delay="0.2s"
                     v-on:click="selectPlatform(2)">
                    <img src="@/assets/images/tower.png" alt="token"/>
                    <h6>More Coming Soon</h6>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Web3 from 'web3'

    export default {
        name: "Platform",
        data() {
            return {
                account: "",
                opensea_api: "https://rinkeby-api.opensea.io/api/v1"
            }
        },
        methods: {
            connectWallet: function () {
                if (typeof window.ethereum !== 'undefined') {
                    // Instance web3 with the provided information
                    window.web3 = new Web3(window.ethereum);
                    try {
                        // Request account access
                        window.ethereum.enable().then(() => this.getActiveAccount());
                        return true
                    } catch (e) {
                        // User denied access
                        return false
                    }
                }
            },
            getActiveAccount: function () {
                window.web3.eth.getAccounts().then((resp) => {
                    this.account = resp[0];
                });
            },

            selectPlatform: function(platform) {
                this.$emit("platform-selected", platform);
                this.$emit("next")
            },

            getOpenSeaEth: function () {
                window.web3.handleRevert = true
                const tokenInst = new window.web3.eth.Contract(this.erc721.abi, "0x88b48f654c30e99bc2e4a1559b4dcf1ad93fa656", {
                    from: this.account, // default from address
                    gasPrice: '20000000000' // default gas price in wei, 20 gwei in this case
                });
                console.log(tokenInst)

                tokenInst.methods.tokenURI("0x495f947276749Ce646f68AC8c248420045cb7b5e").call({from: this.account}).then(resp => console.log(resp))
            },

            getOpenSea: function () {
                fetch(`${this.opensea_api}/assets?owner=${this.account}`)
                    .then(response => response.json())
                    .then(response => console.log(response))
                    .catch(err => console.error(err));
            }
        },

        mounted() {
            this.connectWallet()
        }
    }
</script>

<style scoped>
    .disabled {
        background-color: grey;
    }

    img {
        max-height: 60px;
    }
</style>