<template>
    <div class="row" v-on:click="selectItem" :class="{is_disabled: 'disabled'}">
        <div class="col-3">
            <img class="img-fluid" :src="nft.image_thumbnail_url">
        </div>
        <div class="col-9">
            <div class="row">
                <div class="col-10">
                    <h5>{{nft.name}}</h5>
                </div>
                <div class="col-2">
                    <h5>{{nft.asset_contract.schema_name}}</h5>
                </div>
            </div>
            <p>{{nft.description}}</p>
        </div>
    </div>
</template>

<script>
	import NFTImport from "@/api/nft_import";
	
	export default {
		name: "NFTListItem",
		props: {
			nft: Object
		},
		data() {
			return {
				// BASE_URI: 'http://localhost:9093',
				BASE_URI: 'https://app.babilu.online',
				is_disabled: true,
			}
        },
        methods: {
            availableForImport: function () {
				NFTImport.available(this.nft.asset_contract.address, this.nft.token_id).then((resp) => {
					if (resp.status_code === 200) {
						this.is_disabled = false;
					}
				})
            },
            selectItem: function() {
                if (!this.is_disabled)
                    this.$emit('selected', this.nft)
            }
        },
        mounted() {
            this.availableForImport()
        }
    }
</script>

<style scoped>
    .disabled {
        background-color: grey;
    }

    h5 {
        color: white;
    }
</style>