<template>
    <div class="nft-platform text-center mt-5">
        <button v-if="account == ''" class="btn btn-sm" v-on:click="connectWallet">Connect Wallet</button>

        <div class="row mt-5">
            <div class="col-12">
                <h4>{{account}}</h4>
                <button v-on:click="$emit('next')" class="btn btn-lg mt-5 btn-block">Next</button>
            </div>
        </div>
    </div>
</template>

<script>
    import Web3 from 'web3'

    export default {
        name: "Connect",
        data() {
            return {
                web3: null,
                account: "",
                opensea_api: "https://rinkeby-api.opensea.io/api/v1"
            }
        },
        methods: {
            connectWallet: function () {
                if (typeof window.ethereum !== 'undefined') {
                    // Instance web3 with the provided information
                    this.web3 = new Web3(window.ethereum);
                    try {
                        // Request account access
                        window.ethereum.enable().then(() => this.getActiveAccount());
                        return true
                    } catch (e) {
                        // User denied access
                        return false
                    }
                }
            },
            getActiveAccount: function () {
                this.web3.eth.getAccounts().then((resp) => {
                    this.account = resp[0];
                });
            },
        },

        mounted() {
            this.connectWallet()
        }
    }
</script>

<style scoped>
h4 {
    color: #26b6d4;
}
</style>